import '@hotwired/turbo-rails';
import 'regenerator-runtime/runtime';

import 'utils/polyfills';
import 'stylesheets/careersite';
import 'controllers';
require.context('../images', true);

import { initializeVideoQuestion } from '../career/video_question';
import { hasAcceptedCookieCategory } from '../utils/cookies';
import { iframeResize } from '../career/iframe_resizer';

window.Teamtailor = window.Teamtailor || {};
window.Teamtailor.Ziggeo = {
  initializeVideoQuestion,
};

document.addEventListener('turbo:load', () => {
  document.dispatchEvent(new Event('teamtailor:load')); // Can be used in e.g custom JS
  iframeResize();
  loadVideo();
});

const loadVideo = () => {
  // Load Youtube and Vimeo players if they are used in any block (added by Redactor in career site editor)
  if (
    document.querySelector('lite-youtube') &&
    hasAcceptedCookieCategory('marketing')
  ) {
    import(
      /* webpackChunkName: 'youtube-player' */ '@justinribeiro/lite-youtube'
    );
  }
  if (
    document.querySelector('lite-vimeo') &&
    hasAcceptedCookieCategory('marketing')
  ) {
    import(/* webpackChunkName: 'vimeo-player' */ '@slightlyoff/lite-vimeo');
  }
};

document.addEventListener('teamtailor:cookies-changed', loadVideo);

// #section-X was changed to #X, redirect so old links still work
if (window.location.hash.startsWith('#section-')) {
  window.location.hash = window.location.hash.replace('#section-', '#');
}
