export async function iframeResize() {
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  function sendHeight() {
    if (parent.postMessage) {
      setTimeout(function () {
        parent.postMessage(
          {
            height: document.body.offsetHeight,
            url: window.location.href,
          },
          '*'
        );
      }, 250);
    }
  }

  if (inIframe()) {
    sendHeight();
  }
}
