import { initializeZiggeo } from 'utils/ziggeo';

export async function initializeVideoQuestion(
  locale,
  recordVideoString,
  recordString,
  ziggeoToken,
  clientAuthToken
) {
  const ziggeo = await initializeZiggeo(ziggeoToken);
  ziggeo.Locale.setLocale(locale);
  ziggeo.Locale.mediaLocale.register(
    { 'ba-videorecorder-chooser.record-video': recordVideoString },
    [`language:${locale}`],
    10
  );
  ziggeo.Locale.mediaLocale.register(
    { 'ba-videorecorder-controlbar.record': recordString },
    [`language:${locale}`],
    10
  );

  document
    .querySelectorAll('[id^=video-recorder]')
    .forEach((recorderElement) => {
      const ziggeoInstance = ziggeo.Recorder.findByElement(recorderElement);

      if (!ziggeoInstance) {
        initializeZiggeoOnElement(ziggeo, recorderElement, clientAuthToken);
      }
    });
}

function initializeZiggeoOnElement(ziggeo, recorderElement, clientAuthToken) {
  const wrapperElement = document.querySelector(
    `#video-wrapper-${recorderElement.dataset.questionId}`
  );
  const videoTokenElement = wrapperElement.querySelector(
    'input[name*=video_token]'
  );
  const submitButton = videoTokenElement.form.querySelector('[type=submit]');
  const videoToken = videoTokenElement.value;
  let attributes = {
    width: 420,
    height: 315,
    theme: 'modern',
    themecolor: 'red',
    picksnapshots: false,
    'client-auth': clientAuthToken,
  };

  if (videoToken.length !== 0) {
    attributes = {
      ...attributes,
      video: videoToken,
      rerecordable: true,
      recordermode: false,
    };
  }

  const recorder = new ziggeo.Recorder({
    element: recorderElement,
    attrs: attributes,
  });

  const onRecording = function () {
    submitButton.disabled = true;
  };

  const setVideoToken = function () {
    videoTokenElement.value = recorder.get('video');
    submitButton.disabled = false;
  };

  const onError = function () {
    submitButton.disabled = false;
  };

  recorder.activate();
  recorder.on('countdown recording uploading', onRecording);
  recorder.on('processing verified', setVideoToken);
  recorder.on('error', onError);
}
